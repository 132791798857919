<template>
  <div class="wrapper">
    <header>供应商资料</header>
    <div class="split">
      <div>
        <el-input v-model="inputVal3" @input="hh_inputChange" placeholder="本页搜索"></el-input>
      </div>
      <div>
        <span @click="qh_display_Mode" class="text-xl text-amber-500">
          <span v-if="displayMode =='表格显示'" class=" text-amber-500"><el-icon ><List /></el-icon>卡片</span>
          <span v-else class=" text-teal-500"><el-icon ><Grid /></el-icon>表格</span>
        </span>
      </div>
    </div>

    <!-- 中间滚动区域 -->
    <section>
      <div class="mybody">
        <lzqTable v-if="displayMode =='表格显示'" 
          :tableData_hh_dh="tableData_xm_gx" mytitle="供应商基础资料表头" 
          :IsSelection="false" RowKey="item4"
          @tableRowClick="tableRowClick($event)"
          @handleSelectionChange="handleSelectionChange($event)"
        >
        </lzqTable>
        <div v-if="displayMode =='卡片显示'">
          <el-card class="box-card" v-for="(item,index) in tableData_xm_gx" :key="index" @click="tableRowClick(item)" style="margin:6px;">
            <template #header>
              <div class="card-header">
                <span class="text-3xl text-amber-500">名称:{{item.item1}}</span>
              </div>
            </template>
            <span v-for="(list,indx) in item" :key="indx" class="text item">
              <span v-if="indx>='item2' && indx<='item3'" class="text-2xl text-teal-500">{{ list }}/</span>
            </span>
          </el-card>
        </div>
      </div>
    </section>

    <!-- 中间选择修改删除弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_xgsctj" width="90%">
      <el-row justify="center">
        <el-col ><span class="mybt"><span class="text-green-600">{{formData.khmc}}</span></span></el-col>
        <el-col ><span class="mybt"><span class="text-red-600">请选择操作:</span></span></el-col>
      </el-row>   
      <el-row justify="center">
        <el-col :span="8"><el-button type="warning" @click="btn_xg_sc_qx('修改')" :loading="myloading">修改{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="danger" @click="btn_xg_sc_qx('删除')" :loading="myloading">删除{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="success" @click="btn_xg_sc_qx('取消')" :loading="myloading">取消{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间选择修改删除弹窗结束 -->

    <!-- 中间填写资料弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_kfzl" width="90%">
      <el-row justify="center">
        <el-col ><span class="mybt"><span class="text-red-600">{{infoTxt}}</span></span></el-col>
      </el-row>
      <el-form>
        <div style="margin-top: 10px;margin-bottom: 10px;">
          <el-form-item label="供应商名称" label-width="90px"><el-input v-model="formData.khmc" style="width:150px" /> </el-form-item>
          <el-form-item label="联系人" label-width="90px"><el-input v-model="formData.lxr" style="width:150px" /> </el-form-item>
          <el-form-item label="地址电话" label-width="90px" type="number"><el-input v-model="formData.dh" style="width:150px" /> </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="12"><el-button type="success" @click="save_add_xg_jczl" :loading="myloading">保存{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间填写资料弹窗结束 -->
    <!-- 底部开始 -->
    <footer>
      <div class="mybutton">
        <el-row class="mb-4">
          <el-button type="success" @click="btn_add_jczl">新增供应商</el-button>
          <el-button type="info" @click="toHome">主页</el-button>
        </el-row>
      </div>
    </footer>
    <!-- 底部结束 -->
  </div>
</template>

<script setup>
import myGtdatafun from "../../myapi/myGtdatafun.js";
import dateglobalfun from "../../../dateglobalfun.js"; //日期函数
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import lzqTable from '../truecomponents/lzqTable.vue'//引入自定义组件
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const router = useRouter();
const dialogFormVisible_kfzl = ref(false);
const dialogFormVisible_xgsctj = ref(false);
const myloading = ref(false);
const infoTxt = ref("");
const displayMode = ref("卡片显示");
const inputVal3 = ref("");

const formData = ref({//表格生成
  rq: "",
  dzkhid: "", //订制产品ID
  dzcpid: "", //订制产品ID
  jgkhid: "", //加工产品ID
  jgcpid: "", //加工产品ID
  azdz: "", //安装地址
  azqy: "", //安装区域
  bz: "", //备注
  bm: "", //编码
  clry: "", //测量人员
  dh: "", //单号
  dj: "", //单价
  dw: "", //单位
  dh: "", //电话
  gd: "", //高度
  gg: "", //规格
  je: "", //金额
  jsr: "", //经手人
  kdrq: "", //开单日期
  khdz: "", //产品地址
  khdh: "", //产品电话
  khmc: "", //产品名称
  kd: "", //宽度
  lldh: "", //联络电话
  lxr: "", //联系人
  lxrdh: "", //联系人电话
  mlhclmc: "", //面料或材料名称
  pm: "", //品名
  spbm: "", //商品编码
  spqm: "", //商品全名
  sjazrq: "", //实际安装日期
  sl: "", //数量
  shdz: "微信", //送货地址
  shdz: "", //送货地址
  xdrq: "", //下单日期
  xh: "", //序号
  yqazrq: "", //要求安装日期
  zdr: "", //制单人
  cztj: "tjjczl", //操作条件
  yhmc: "", //token
  qxxm: "", //权限项目
  id: "1", //
  endrq: "", //结束日期
});

const qh_display_Mode = () =>{
  if (displayMode.value=='表格显示') {
    displayMode.value='卡片显示'
  }else{
    displayMode.value='表格显示'
  }
}

onMounted(() => {//页面进入时
  formData.value.yhmc = localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.xm = localStorage.getItem(myGtdatafun.XMMC + "xm"); //姓名
  formData.value.gjgx = localStorage.getItem(myGtdatafun.XMMC + "gx"); //工价工序
  formData.value.qxxm == "新增"
  formData.value.cztj = "初始获取供应商基础资料";
  khjczl_get();
});

const btn_xg_sc_qx = (tjstr) => {
  switch (tjstr) {
    case "修改":
      infoTxt.value="修改资料"
      formData.value.cztj = "保存修改供应商资料";
      dialogFormVisible_kfzl.value = true
      dialogFormVisible_xgsctj.value = false
    break;
    case "删除":
      dialogFormVisible_xgsctj.value = false
      ElMessageBox.confirm(
        "[" + formData.value.khmc  + "]确认删除吗?",
        "确认删除吗?",
        {confirmButtonText: "删除",cancelButtonText: "取消",type: "error",}
      )
        .then(() => {
          formData.value.cztj = "手机端删除一行供应商资料";
          myGtdatafun
            .MygettableData("html_dysj_ZSG.php", formData.value)
            .then(function (response) {
              console.log(response);
              location.reload(); //重新加载本页
            });
        })
        .catch(() => {console.log('取消删除一行');dialogFormVisible_xgsctj.value = false});
    break;
    case "取消":
      dialogFormVisible_xgsctj.value = false
    break;
  }  
}
const save_add_xg_jczl = () => {//修改
  console.log('保存save_add_xg_jc8zl',formData.value);
  if (formData.value.khmc == "") {
    ElMessage({ message: '名称不能为空,保存不成功!', type: 'error', });
    return;
  }
  let cansavekh = 'yes'; 
  // tableData_xm_gx.forEach((ite, index) => {//供应商名称重复,不保存
  //   if (ite.item1 == formData.value.khmc) {
  //     ElMessage({ message: '名称重复了,保存不成功!', type: 'error', });
  //     cansavekh = 'no';
  //   }
  // });
  if (cansavekh == 'no') {
    dialogFormVisible_kfzl.value = false;//隐藏弹窗
    formData.value.khmc = ""; formData.value.lxr = "";formData.value.dh = "";
    return;
  }
  myloading.value = true 
  
  khjczl_get();
  dialogFormVisible_kfzl.value = false;
  formData.value.khmc = ""; formData.value.lxr = "";formData.value.dh = "";
} 

const handleSelectionChange = (arr) => {//选择复选框
  console.log('handleSelectionChange[]', arr);
  // let ids = "";
  arr.forEach((item, index) => {
    formData.value.khmc = item.item1
    formData.value.id = item.item3
    // if (index == 0) {
    //   ids = item.item3;
    // } else {
    //   ids = ids + "," + item.item3;
    // }
  });
  console.log("handleSelectionChange", formData.value);
}

const tableRowClick = (row) => {//点击一行跳转
  if (row.item1 == '查无资料') return
  console.log('修改删除',row);
  dialogFormVisible_xgsctj.value=true;
  formData.value.khmc=row.item1
  formData.value.lxr=row.item2
  formData.value.dh=row.item3
  formData.value.id=row.item4
};

const khjczl_get = async () => {//提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value) 
    .then(function (response) {
      console.log( '11111',response, formData.value);
      myloading.value = false
      if (response.length <= 0) { return }
      formData.value.qxxm = ""
      switch (formData.value.cztj) {
        case "初始获取供应商基础资料":
          tableData_xm_gx.length = 0;
          tableData_xm_gx_base.length = 0;
          response.forEach((item) => {
            tableData_xm_gx.push(item);
            tableData_xm_gx_base.push(item);
          });
          break;
        case "手机端新增供应商资料":
          location.reload(); //重新加载本页
          break;
        case "保存修改供应商资料":
          location.reload(); //重新加载本页
          break;
        case "手机端删除一行供应商资料":
          location.reload(); //重新加载本页
          break;
      }
    })
    .catch(function (error) {});
};

const toHome = () => {
  router.push("/");
};
const btn_add_jczl = () => {
  infoTxt.value="新增资料"
  formData.value.khmc=""
  formData.value.lxr=""
  formData.value.dh=""
  formData.value.id=""
  formData.value.cztj="手机端新增供应商资料"
  dialogFormVisible_kfzl.value = true
};

const hh_inputChange = async (e) => {//本页面筛选
  tableData_xm_gx.length = 0; //先清空显示的数组
  tableData_xm_gx_base.forEach((item, index) => {
    if (item.item1.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item2.indexOf(inputVal3.value) >= 0
    ) {
      tableData_xm_gx.push(item); //再把选到的加入显示数组
    }
  });
};

</script>
<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header,
footer {
  height: 40px;
  line-height: 40px;
  background-color: #d8d8d8;
  text-align: center;
}

section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* winphone8和android4+ */
  -webkit-overflow-scrolling: touch;
  /* ios5+ */
}

.mybutton {
  display: flex;
  justify-content: center;
}

:deep(.el-collapse-item__header) {
  color: red;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-form-item__label) {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-collapse-item__content) {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.mybt {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
}

:deep(.el-carousel__container) {
  height: 380px;
}

img {
  height: 380px;
}

.split {
  margin: 6px; margin-bottom: 10px; display: flex;
  justify-content: space-between; align-items: center;
}
</style>